var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-toolbar-title',[_vm._v("Zestawienie transakcji")]),_c('v-spacer'),(_vm.hasAccessTo('customer'))?_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"depressed":"","loading":_vm.state.exportLoading}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('span',[_vm._v("Eksportuj")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Eksportuj zestawienie na podstawie obecnych filtrów")])])]}}],null,false,1668086534)},[_c('v-list',{attrs:{"min-width":"250","dense":"","subheader":""}},[_c('v-subheader',{staticClass:"px-4"},[_vm._v("Wybierz format")]),_c('v-list-item',{on:{"click":function($event){return _vm.triggerExport('xls')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-excel-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Excel (XLSX)")])],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-download")])],1)],1)],1)],1):_vm._e(),(_vm.hasAccessTo('customer'))?_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"depressed":"","dark":"","color":"green"}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('span',[_vm._v("Importuj")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-upload")])],1)]}}],null,true)},[_c('span',[_vm._v("Importuj zestawienie transakcji")])])]}}],null,false,33797231)},[_c('v-list',{attrs:{"min-width":"250","dense":"","subheader":""}},[_c('v-subheader',{staticClass:"px-4"},[_vm._v("Wybierz operatora kart paliwowych")]),_c('v-list-item',{attrs:{"to":{ name: 'panel.fuel-card-transaction.add', query: { type: 'orlen' } }}},[_c('v-list-item-avatar',{attrs:{"tile":"","size":"24"}},[_c('v-img',{attrs:{"src":require('@/assets/img/brands/orlen.svg')}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Orlen")])],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-upload")])],1)],1),_c('v-list-item',{attrs:{"to":{ name: 'panel.fuel-card-transaction.add', query: { type: 'bp' } }}},[_c('v-list-item-avatar',{attrs:{"tile":"","size":"24"}},[_c('v-img',{attrs:{"src":require('@/assets/img/brands/bp.svg')}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("BP")])],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-upload")])],1)],1),_c('v-list-item',{attrs:{"to":{ name: 'panel.fuel-card-transaction.add', query: { type: 'flotex' } }}},[_c('v-list-item-avatar',{attrs:{"tile":"","size":"24"}},[_c('v-img',{attrs:{"src":require('@/assets/img/brands/flotex.svg')}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Flotex")])],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-upload")])],1)],1)],1)],1):_vm._e()],1),_c('o-fuel-card-transactions-list',{ref:"list"})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }