



















































import { defineComponent, reactive, ref } from "@vue/composition-api";
import OFuelCardTransactionsList from "@/components/organisms/o-fuel-card-transactions-list.vue";
import useUser from "@/use/user";

export default defineComponent({
  components: { OFuelCardTransactionsList },

  setup(_, { root }) {
    const list = ref<typeof OFuelCardTransactionsList>();

    const state = reactive({
      exportLoading: false,
    });

    const { hasAccessTo } = useUser({ root });

    const triggerExport = (format = "xls") => {
      state.exportLoading = true;

      const el = (list.value as any) as {
        fetchExport(format: string): Promise<void>;
      };
      el?.fetchExport(format).finally(() => (state.exportLoading = false));
    };

    return { list, state, hasAccessTo, triggerExport };
  },
});
